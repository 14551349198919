import { FormEvent, useState } from "react";

import styled from "styled-components";

import apiAxios from "../helpers/apiAxios";

import { AxiosError } from "axios";

const ContactUsSection = () => {
  const [processing, setProcessing] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState<string | null>(null);

  const [phone, setPhone] = useState<string | null>(null);

  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const [message, setMessage] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setProcessing(true);

    apiAxios

      .post("/website/contact-us-form-submissions", {
        name,

        phone,

        emailAddress,

        message,
      })

      .then(
        () => {
          setProcessing(false);

          setSubmitted(true);

          const interval = setTimeout(() => {
            clearInterval(interval);

            setName(null);

            setPhone(null);

            setEmailAddress(null);

            setMessage(null);

            setSubmitted(false);
          }, 60000);
        },

        (
          axiosError: AxiosError<{
            errorCode: string;
          }>
        ) => {
          setProcessing(false);

          if (axiosError.response?.data.errorCode)
            alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <Title>Contact Us</Title>

      <ContentContainer>
        {!submitted ? (
          <FormContainer>
            <Heading>Send us a message!</Heading>

            <Form onSubmit={(e) => handleSubmit(e)}>
              <InputContainer>
                <input
                  disabled={processing}
                  placeholder="Name *"
                  type="text"
                  required
                  value={name || ""}
                  onChange={(e) => setName(e.target.value || null)}
                />
              </InputContainer>

              <InputContainer>
                <input
                  disabled={processing}
                  placeholder="Phone *"
                  type="text"
                  required
                  value={phone || ""}
                  onChange={(e) => setPhone(e.target.value || null)}
                />
              </InputContainer>

              <InputContainer>
                <input
                  disabled={processing}
                  placeholder="Email Address*"
                  type="email"
                  required
                  value={emailAddress || ""}
                  onChange={(e) => setEmailAddress(e.target.value || null)}
                />
              </InputContainer>

              <InputContainer>
                <textarea
                  disabled={processing}
                  placeholder="Message *"
                  required
                  value={message || ""}
                  onChange={(e) => setMessage(e.target.value || null)}
                />
              </InputContainer>

              <ButtonContainer>
                <button disabled={processing}>Send</button>
              </ButtonContainer>
            </Form>
          </FormContainer>
        ) : (
          <ThankyouContainer>
            <ThankyouTitle>AMAZING!</ThankyouTitle>

            <ThankyouText>
              We've successfully received your message and we'll be in touch
              within the next 24 hours.
            </ThankyouText>
          </ThankyouContainer>
        )}

        <DetailsContainer>
          <Heading>Visit our office!</Heading>

          <Descriptive>
            Feel free to visit our office in person during normal business
            hours. You can also contact us by email or phone. Just use our
            contact info below.
          </Descriptive>

          <ContactInfoContainer>
            <CompanyName>AMAZING FLIPS, LLC</CompanyName>

            <CompanyAddress>
              2937 W Indian School Rd Phoenix AZ 85017
            </CompanyAddress>

            <CompanyEmailAddress href="mailto:contact@AmazingFlips.com">
              contact@AmazingFlips.com
            </CompanyEmailAddress>

            <CompanyPhoneNumber>
              Phone:
              <a href="tel:6022629464">602-262-9464</a>
            </CompanyPhoneNumber>

            <CompanyFaxNumber>
              Fax:
              <a href="tel:6023210400">602-321-0400</a>
            </CompanyFaxNumber>
          </ContactInfoContainer>

          <Heading>Hours</Heading>

          <Descriptive>Mon thru Sat, 9am until 7pm</Descriptive>
        </DetailsContainer>
      </ContentContainer>
    </Container>
  );
};

export default ContactUsSection;

const Container = styled.div`
  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;

  display: grid;
`;

const Title = styled.div`
  color: #283878;

  font-size: 2rem;

  text-align: center;
`;

const ContentContainer = styled.div`
  padding: 1rem;

  display: grid;

  gap: 1rem;

  justify-content: space-evenly;

  @media (min-width: 600px) {
    grid-template-columns: 0.4fr 0.4fr;
  }
`;

const FormContainer = styled.div`
  display: grid;

  gap: 1rem;
`;

const Heading = styled.div`
  font-size: 1.5rem;
`;

const Descriptive = styled.div``;

const Form = styled.form`
  display: grid;

  gap: 0.5rem;
`;

const InputContainer = styled.div`
  input,
  textarea {
    border-radius: 5px;

    border: solid 1px #c9c9c9;

    &:hover {
      border: solid 1px #283878;
    }

    outline: 0;

    cursor: pointer;

    color: #283878;

    font-size: 1rem;

    width: 100%;

    padding: 0.5rem 1rem;
  }

  textarea {
    resize: vertical;

    min-height: 10rem;
  }
`;

const ButtonContainer = styled.div`
  display: grid;

  justify-content: center;

  button {
    background-color: #283878;

    color: #ffffff;

    font-weight: 700;

    border-radius: 10px;

    outline: 0;

    cursor: pointer;

    text-transform: uppercase;

    font-size: 1rem;

    padding: 0.5rem 1rem;
  }
`;

const DetailsContainer = styled.div`
  display: grid;

  gap: 1rem;
`;

const ContactInfoContainer = styled.div`
  display: grid;

  gap: 1rem;
`;

const CompanyName = styled.div`
  font-size: 1.5rem;

  font-weight: 400;
`;

const CompanyAddress = styled.div``;

const CompanyEmailAddress = styled.a`
  text-decoration: none;

  color: #283878;

  &:hover {
    font-weight: 600;
  }
`;

const CompanyPhoneNumber = styled.div`
  display: flex;

  a {
    margin-left: 0.5rem;

    text-decoration: none;

    color: #283878;

    &:hover {
      font-weight: 600;
    }
  }
`;

const CompanyFaxNumber = styled.div`
  display: flex;

  a {
    margin-left: 0.5rem;

    text-decoration: none;

    color: #283878;

    &:hover {
      font-weight: 600;
    }
  }
`;

const ThankyouContainer = styled.div`
  display: flex;

  gap: 1rem;

  flex-direction: column;
`;

const ThankyouTitle = styled.div`
  font-size: 1.5rem;
`;

const ThankyouText = styled.div`
  font-size: 1rem;
`;
