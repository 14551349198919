import { useEffect, useState } from "react";

import styled from "styled-components";

import apiAxios from "../helpers/apiAxios";

import { AxiosError } from "axios";

import { IPhoto, IProperty } from "../helpers/commonInterfaces";

import { useParams } from "react-router";

import config from "../helpers/config";

import ImageHoverZoom from "./HoverImageZoom";

const PropertyPage = () => {
  const { slug } = useParams();

  const [property, setProperty] = useState<IProperty | null>(null);

  const [photos, setPhotos] = useState<IPhoto[] | null>(null);

  const [imageToShow, setImageToShow] = useState("");

  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  useEffect(() => {
    if (!slug) return;

    apiAxios.get(`/website/properties/${slug}`).then(
      (axiosResponse) => setProperty(axiosResponse.data.property),

      (
        axiosError: AxiosError<{
          errorCode: string;
        }>
      ) => {
        if (axiosError.response?.data.errorCode)
          alert(
            `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
          );

        alert(
          `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
        );
      }
    );

    apiAxios.get(`/website/properties/${slug}/photos`).then(
      (axiosResponse) => setPhotos(axiosResponse.data.photos),

      (
        axiosError: AxiosError<{
          errorCode: string;
        }>
      ) => {
        if (axiosError.response?.data.errorCode)
          alert(
            `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
          );
      }
    );
  }, [slug]);

  if (!property) return;

  if (!photos) return;

  const photoGallery =
    (photos &&
      photos.map(
        (secondaryPhoto) =>
          `${config.apiBaseUrl}/handlers/properties/${slug}/photos/${secondaryPhoto.id}`
      )) ||
    null;

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",

    currency: "USD",

    minimumFractionDigits: 0,

    maximumFractionDigits: 0,
  });

  const showImage = (image: string) => {
    setImageToShow(image);

    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    let currentIndex = photoGallery.indexOf(imageToShow);

    if (currentIndex >= photoGallery.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = photoGallery[currentIndex + 1];

      setImageToShow(nextImage!);
    }
  };

  const showPrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    let currentIndex = photoGallery.indexOf(imageToShow);

    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let prevImage = photoGallery[currentIndex - 1];

      setImageToShow(prevImage!);
    }
  };

  return (
    <Container>
      <PropertyAddress>{property.address}</PropertyAddress>

      <PhotoContainer>
        <PrimaryPhoto
          src={`${config.apiBaseUrl}/handlers/properties/${slug}/photos/primary`}
          alt={`Primary photo for ${property.address}`}
        />

        {property.status === 1 && <UcbStamp>UCB</UcbStamp>}
      </PhotoContainer>

      <PropertyDescriptive>{property.descriptive}</PropertyDescriptive>

      <PropertyValues>
        Price {currencyFormatter.format(property.price)} - ARV{" "}
        {currencyFormatter.format(property.arv)}
      </PropertyValues>

      <PhotoGallery>Photo Gallery</PhotoGallery>

      <Photos>
        {photoGallery.slice(1).map((url, index) => (
          <ImageHoverZoom
            key={index}
            src={url}
            alt=""
            onClick={() => showImage(url)}
          />
        ))}
      </Photos>

      {lightboxDisplay ? (
        <Lightbox onClick={hideLightBox}>
          <button onClick={showPrev}>⭠</button>

          <LightboxImage src={imageToShow} alt="" />

          <button onClick={showNext}>⭢</button>
        </Lightbox>
      ) : (
        ""
      )}
    </Container>
  );
};

export default PropertyPage;

const Container = styled.div`
  margin-top: 150px;

  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;

  display: grid;

  gap: 1rem;
`;

const PropertyAddress = styled.div`
  font-size: 2.5rem;

  color: #283878;

  background-color: #f6f6f6;

  padding: 1rem;

  text-transform: uppercase;

  font-weight: bold;

  letter-spacing: 3px;

  text-align: center;
`;

const PropertyValues = styled.div`
  font-weight: bold;

  font-size: 3rem;

  white-space: pre-line;

  letter-spacing: 3px;

  text-align: center;
`;

const PropertyDescriptive = styled.div`
  font-weight: bold;

  font-size: 1.4rem;

  white-space: pre-line;

  letter-spacing: 3px;

  text-align: center;
`;

const PhotoGallery = styled.div`
  font-size: 1.5rem;

  text-align: center;

  background-color: #f6f6f6;

  padding: 1rem;

  text-transform: uppercase;
`;

const Photos = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  gap: 1rem;

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const PhotoContainer = styled.div`
  position: relative;

  display: flex;

  justify-content: space-around;

  align-items: center;
`;

const PrimaryPhoto = styled.img`
  width: 100%;

  border: solid 1px #c9c9c9;
`;

const UcbStamp = styled.div`
  position: absolute;

  transform: rotate(-14deg);

  font-size: 4rem;

  font-weight: 700;

  color: green;

  border: 0.5rem solid green;

  display: inline-block;

  padding: 0.25rem 1rem;

  text-transform: uppercase;

  border-radius: 1rem;

  font-family: "Courier";

  mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  mask-size: 944px 604px;

  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  -webkit-mask-size: 944px 604px;

  mix-blend-mode: multiply;

  transform: rotate(-20deg);

  @media (min-width: 800px) {
    font-size: 12rem;
  }
`;

const Lightbox = styled.div`
  z-index: 1;

  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  background-color: rgba(0, 0, 0, 0.7);

  display: flex;

  align-items: center;

  justify-content: space-between;
`;

const LightboxImage = styled.img`
  height: 80vh;

  max-width: 90vw;

  object-fit: cover;
`;
