import { useState } from "react";

import styled from "styled-components";

import PropertyPane from "./PropertyPane";

import apiAxios from "../helpers/apiAxios";

import { AxiosError } from "axios";

import { IProperty } from "../helpers/commonInterfaces";

const OurPropertiesSection = () => {
  const [properties, setProperties] = useState<IProperty[] | null>(null);

  useState(() =>
    apiAxios.get("/website/properties").then(
      (axiosResponse) => setProperties(axiosResponse.data.properties),

      (
        axiosError: AxiosError<{
          errorCode: string;
        }>
      ) => {
        if (axiosError.response?.data.errorCode)
          alert(
            `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
          );

        alert(
          `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
        );
      }
    )
  );

  return (
    <Container>
      <Title>Our Properties</Title>

      <ContentContainer>
        <PropertiesContainer>
          {properties &&
            properties.map((property, index) => (
              <PropertyPane key={index} property={property} />
            ))}
        </PropertiesContainer>

        <Footer>
          Call 602-262-9464 if you have any questions about our available
          wholesale properties
        </Footer>
      </ContentContainer>
    </Container>
  );
};

export default OurPropertiesSection;

const Container = styled.div`
  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;

  display: grid;
`;

const Title = styled.div`
  color: #283878;

  font-size: 2rem;

  text-align: center;

  @media (min-width: 800px) {
    font-size: 3rem;
  }
`;

const ContentContainer = styled.div`
  padding: 1rem;

  display: grid;

  gap: 1rem;
`;

const PropertiesContainer = styled.div`
  display: grid;

  gap: 1rem;

  @media (min-width: 700px) {
    display: grid;

    grid-template-columns: 0.4fr 0.4fr;

    gap: 1rem;

    justify-content: space-evenly;
  }
`;

const Footer = styled.div`
  font-size: 0.9rem;

  text-align: center;
`;
