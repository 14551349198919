import styled from "styled-components";

const ImageHoverZoom = ({
  src,

  alt,

  onClick,
}: {
  src: string;

  alt: string;

  onClick: () => void;
}) => (
  <Container>
    <Image src={src} alt={alt} onClick={onClick} />
  </Container>
);

export default ImageHoverZoom;

const Container = styled.div`
  overflow: hidden;

  width: 100%;

  height: auto;
`;

const Image = styled.img`
  transition: all 0.3s ease 0s;

  width: 100%;

  &:hover {
    transform: scale(1.25);
  }
`;
