import styled from "styled-components";

import aboutAmazingFlipsPhoto from "../assets/about-photo-image.jfif";

const AboutAmazingFlipsSection = () => (
  <Container>
    <Title>About Amazing Flips</Title>

    <ContentContainer>
      <PhotoContainer>
        <Photo src={aboutAmazingFlipsPhoto}></Photo>
      </PhotoContainer>

      <Text>
        At Amazing Flips we believe in providing GREAT wholesale deals to our
        buyers, because our team has been involved in every area of wholesale
        real estate for over 20 years! Most importantly we understand how
        important it is to know that you are buying right when you want to be
        profitable &amp; successful in this business!
      </Text>
    </ContentContainer>
  </Container>
);

export default AboutAmazingFlipsSection;

const Container = styled.div`
  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;

  display: grid;
`;

const Title = styled.div`
  color: #283878;

  font-size: 2rem;

  text-align: center;

  @media (min-width: 800px) {
    font-size: 3rem;
  }
`;

const ContentContainer = styled.div`
  padding: 1rem;

  display: grid;

  gap: 2rem;

  align-items: center;

  justify-content: center;

  @media (min-width: 750px) {
    grid-template-columns: 0.5fr 0.5fr;
  }
`;

const PhotoContainer = styled.div``;

const Photo = styled.img`
  width: 100%;
`;

const Text = styled.div`
  line-height: 30px;

  font-size: 1.2rem;

  @media (min-width: 750px) {
    grid-template-columns: 0.5fr 0.5fr;

    line-height: 40px;

    font-size: 1.5rem;
  }
`;
