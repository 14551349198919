import config from "../helpers/config";

import styled from "styled-components";

import "react-18-image-lightbox/style.css";

import { IProperty } from "../helpers/commonInterfaces";

interface IProps {
  property: IProperty;
}

const PropertyPane = (props: IProps) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",

    currency: "USD",

    minimumFractionDigits: 0,

    maximumFractionDigits: 0,
  });

  if (props.property.status === 2)
    return (
      <Container>
        <PhotoContainer>
          <Photo
            src={`${config.apiBaseUrl}/handlers/properties/${props.property.slug}/photos/primary`}
            alt={`Primary photo for ${props.property.address}`}
          />

          <SoldStamp>Sold</SoldStamp>
        </PhotoContainer>

        <DetailsContainer>
          <AddressAndPriceContainer>
            <Address>{props.property.address}</Address>

            <Price>
              PRICE {currencyFormatter.format(props.property.price)}
            </Price>
          </AddressAndPriceContainer>

          <ARV>ARV {currencyFormatter.format(props.property.arv)}</ARV>

          <Descriptive>{props.property.descriptive}</Descriptive>
        </DetailsContainer>
      </Container>
    );

  return (
    <Container
      onClick={() => {
        window.location.href = `/properties/${props.property.slug}`;
      }}
    >
      <PhotoContainer>
        <Photo
          src={`${config.apiBaseUrl}/handlers/properties/${props.property.slug}/photos/primary`}
          alt={`Primary photo for ${props.property.address}`}
        />

        {props.property.status === 1 && <UcbStamp>UCB</UcbStamp>}
      </PhotoContainer>

      <DetailsContainer>
        <AddressAndPriceContainer>
          <Address>{props.property.address}</Address>

          <Price>PRICE {currencyFormatter.format(props.property.price)}</Price>
        </AddressAndPriceContainer>

        <ARV>ARV {currencyFormatter.format(props.property.arv)}</ARV>

        <Descriptive>{props.property.descriptive}</Descriptive>
      </DetailsContainer>
    </Container>
  );
};

export default PropertyPane;

const Container = styled.div`
  border: solid 1px #c9c9c9;

  cursor: pointer;

  &:hover {
    border: solid 1px #283878;
  }

  text-decoration: none;

  padding: 1rem;

  display: flex;

  gap: 1rem;

  flex-direction: column;
`;

const PhotoContainer = styled.div`
  position: relative;

  display: flex;

  justify-content: space-around;

  align-items: center;
`;

const Photo = styled.img`
  width: 100%;

  border: solid 1px #c9c9c9;
`;

const UcbStamp = styled.div`
  position: absolute;

  transform: rotate(-14deg);

  font-size: 4rem;

  font-weight: 700;

  color: green;

  border: 0.5rem solid green;

  display: inline-block;

  padding: 0.25rem 1rem;

  text-transform: uppercase;

  border-radius: 1rem;

  font-family: "Courier";

  mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  mask-size: 944px 604px;

  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  -webkit-mask-size: 944px 604px;

  mix-blend-mode: multiply;

  transform: rotate(-20deg);

  @media (min-width: 800px) {
    font-size: 6rem;
  }
`;

const SoldStamp = styled.div`
  position: absolute;

  transform: rotate(-14deg);

  font-size: 4rem;

  font-weight: 700;

  color: red;

  border: 0.5rem solid red;

  display: inline-block;

  padding: 0.25rem 1rem;

  text-transform: uppercase;

  border-radius: 1rem;

  font-family: "Courier";

  mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  mask-size: 944px 604px;

  -webkit-mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");

  -webkit-mask-size: 944px 604px;

  mix-blend-mode: multiply;

  transform: rotate(-20deg);

  @media (min-width: 800px) {
    font-size: 6rem;
  }
`;

const DetailsContainer = styled.div`
  display: grid;

  gap: 0.5rem;

  white-space: pre-line;
`;

const AddressAndPriceContainer = styled.div`
  display: grid;

  gap: 0.5rem;
`;

const Address = styled.div``;

const Price = styled.div`
  font-weight: 600;
`;

const ARV = styled.div`
  font-weight: 600;
`;

const Descriptive = styled.div``;
