import styled from "styled-components";

import headerVideo from "../assets/header-video.mp4";

const HeroSection = () => (
  <Container>
    <VideoContainer>
      <Video playsInline autoPlay muted src={headerVideo} />
    </VideoContainer>
  </Container>
);

export default HeroSection;

const Container = styled.div`
  position: relative;
`;

const VideoContainer = styled.div``;

const Video = styled.video`
  width: 100%;
`;
