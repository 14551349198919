import styled from "styled-components";

const FooterSection = () => (
  <Container>
    © {new Date().getFullYear()} Amazing Flips, LLC - All Rights Reserved.
  </Container>
);

export default FooterSection;

const Container = styled.div`
  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;

  text-align: center;

  font-size: 0.8em;
`;
