import styled from "styled-components";

import HeroSection from "./HeroSection";

import JoinOurEmailListSection from "./JoinOurEmailListSection";

import OurPropertiesSection from "./OurPropertiesSection";

import AboutAmazingFlipsSection from "./AboutAmazingFlipsSection";

import ContactUsSection from "./ContactUsSection";

const HomePage = () => (
  <Container>
    <HeroSectionContainer>
      <HeroSection />
    </HeroSectionContainer>

    <JoinOurEmailListSectionContainer>
      <JoinOurEmailListSection />
    </JoinOurEmailListSectionContainer>

    <OurPropertiesSectionContainer>
      <OurPropertiesSection />
    </OurPropertiesSectionContainer>

    <AboutAmazingFlipsSectionContainer>
      <AboutAmazingFlipsSection />
    </AboutAmazingFlipsSectionContainer>

    <ContactUsSectionContainer>
      <ContactUsSection />
    </ContactUsSectionContainer>
  </Container>
);

export default HomePage;

const Container = styled.div`
  display: grid;
`;

const HeroSectionContainer = styled.div``;

const JoinOurEmailListSectionContainer = styled.div`
  background-color: #f6f6f6;
`;

const OurPropertiesSectionContainer = styled.div``;

const AboutAmazingFlipsSectionContainer = styled.div`
  background-color: #f6f6f6;
`;

const ContactUsSectionContainer = styled.div``;
