import { FormEvent, useState } from "react";

import styled from "styled-components";

import apiAxios from "../helpers/apiAxios";

import { AxiosError } from "axios";

const JoinOurEmailListSection = () => {
  const [processing, setProcessing] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState<string | null>(null);

  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setProcessing(true);

    apiAxios

      .post("/website/email-list/subscribe", {
        name,

        emailAddress,
      })

      .then(
        () => {
          setProcessing(false);

          setSubmitted(true);

          const interval = setTimeout(() => {
            clearInterval(interval);

            setName(null);

            setEmailAddress(null);

            setSubmitted(false);
          }, 60000);
        },

        (
          axiosError: AxiosError<{
            errorCode: string;
          }>
        ) => {
          setProcessing(false);

          if (
            axiosError.response?.data.errorCode === "duplicateEmailListMember"
          )
            return alert(
              "There is already a subscription for this email address.\n\nPlease contact us if you need further assistance."
            );

          if (axiosError.response?.data.errorCode)
            alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      {!submitted ? (
        <FormContainer onSubmit={(e) => handleSubmit(e)}>
          <CallToAction>Join Our Email List</CallToAction>

          <InputsContainer>
            <InputContainer>
              <input
                disabled={processing}
                placeholder="Name *"
                required
                value={name || ""}
                onChange={(e) => setName(e.target.value || null)}
              />
            </InputContainer>

            <InputContainer>
              <input
                disabled={processing}
                placeholder="Email *"
                type="email"
                required
                value={emailAddress || ""}
                onChange={(e) => setEmailAddress(e.target.value || null)}
              />
            </InputContainer>
          </InputsContainer>

          <ButtonContainer>
            <button type="submit" disabled={processing}>
              Sign Up
            </button>
          </ButtonContainer>
        </FormContainer>
      ) : (
        <ThankyouContainer>
          <ThankyouTitle>AMAZING!</ThankyouTitle>

          <ThankyouText>
            We are very excited to send you our next hot property. To confirm
            it&apos;s really you and that you want to receive emails from
            AmazingFlips.com, we have sent you a confirmation email, please
            click the link in that email to start receiving our email updates.
            You can unsubscribe from these emails at any time.
          </ThankyouText>
        </ThankyouContainer>
      )}
    </Container>
  );
};

export default JoinOurEmailListSection;

const Container = styled.div`
  max-width: 1232px;

  margin-left: auto;

  margin-right: auto;

  padding: 1rem;
`;

const FormContainer = styled.form`
  margin-left: auto;

  margin-right: auto;

  max-width: 800px;

  display: flex;

  flex-direction: column;

  gap: 1rem;

  align-items: center;

  @media (min-width: 400px) {
    grid-template-columns: auto 1fr auto;

    @media (min-width: 800px) {
      grid-template-columns: auto 1fr auto;

      gap: 2rem;
    }
  }
`;

const InputsContainer = styled.form`
  margin-left: auto;

  margin-right: auto;

  max-width: 800px;

  display: flex;

  flex-direction: column;

  gap: 1rem;

  align-items: center;

  @media (min-width: 400px) {
    flex-direction: row;
  }
`;

const CallToAction = styled.div`
  display: grid;

  align-items: center;

  text-transform: uppercase;

  color: #283878;

  text-align: center;

  font-size: 1.2rem;

  @media (min-width: 400px) {
    font-size: 0.8rem;

    @media (min-width: 800px) {
      font-size: 2rem;
    }
  }
`;

const InputContainer = styled.div`
  input {
    border-radius: 5px;

    border: solid 1px #c9c9c9;

    &:hover {
      border: solid 1px #283878;
    }

    outline: 0;

    cursor: pointer;

    color: #283878;

    font-size: 1rem;

    @media (min-width: 800px) {
      font-size: 1.5rem;
    }

    width: 100%;

    padding: 0.5rem 1rem;
  }

  textarea {
    resize: vertical;
  }
`;

const ButtonContainer = styled.div`
  display: grid;

  justify-content: center;

  button {
    background-color: #283878;

    color: #ffffff;

    font-weight: 700;

    border-radius: 10px;

    outline: 0;

    cursor: pointer;

    text-transform: uppercase;

    font-size: 0.8rem;

    @media (min-width: 800px) {
      font-size: 2rem;
    }

    padding: 0.5rem 1rem;
  }
`;

const ThankyouContainer = styled.div`
  margin-left: auto;

  margin-right: auto;

  max-width: 500px;
`;

const ThankyouTitle = styled.div`
  color: #283878;

  text-align: center;

  font-size: 1.5rem;
`;

const ThankyouText = styled.div`
  font-size: 1.3rem;
`;
