import { BrowserRouter, Routes, Route } from "react-router-dom";

import styled from "styled-components";

import GlobalStyle from "./GlobalStyle";

import HeaderSection from "./components/HeaderSection";

import HomePage from "./components/HomePage";

import PropertyPage from "./components/PropertyPage";

import FooterSection from "./components/FooterSection";

const App = () => (
  <BrowserRouter>
    <Container>
      <GlobalStyle />

      <HeaderContainer>
        <HeaderSection />
      </HeaderContainer>

      <PagesContainer>
        <Routes>
          <Route>
            <Route path="/" element={<HomePage />} />

            <Route path="/properties/:slug" element={<PropertyPage />} />
          </Route>
        </Routes>
      </PagesContainer>

      <FooterContainer>
        <FooterSection />
      </FooterContainer>
    </Container>
  </BrowserRouter>
);

export default App;

const Container = styled.div`
  display: grid;
`;

const HeaderContainer = styled.div`
  position: absolute;

  left: 0;

  right: 0;

  z-index: 1;

  background-color: rgba(255, 255, 255, 0.7);
`;

const PagesContainer = styled.div``;

const FooterContainer = styled.div``;
